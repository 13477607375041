
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

import KakaoHelper from '@/utils/KakaoHelper';
export default {
  name: 'SectionWebtoon',
  props: {
    webtoonList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: '1',
        centeredSlides: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },

      },
      currentIndex1: 1,
      swiperOptions2: {
        slidesPerView: '1.4',
        centeredSlides: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },

        // loop: true,
      },
      currentIndex2: 1,
      politicList: [],
      is_like: false,
      jayuId: null, // 자유일보 만평 id
    }
  },
  computed: {
  },
  methods: {
    initSwiper() {
      this.swiper = new Swiper('.swiper-container');
    },
    async getPolitic() {
      const response = await this.$axios.get(`/api/politic`);
      this.politicList = response.data.data.sort((a, b) => b.id - a.id);

    },
    kakaoShare(item) {
      this.cutUrl(item.video_url);
      this.kakaoHelper.initSharePolitic(item, this.jayuId);
    },
    slideChange() {
      this.currentIndex1 = this.$refs.mySwiper.$swiper.activeIndex + 1
    },
    slideChange2() {
      this.currentIndex2 = this.$refs.mySwiper2.$swiper.activeIndex + 1
    },
    toggleLike(item) {
      // e.preventDefault();
      // e.stopPropagation();

      if (!this.$auth.user)
        return alert("로그인 후 이용 부탁드립니다.");

      if (item.isLike) {
        item.isLike = 0;
        item.like_count -= 1;
      } else {
        item.isLike = 1;
        item.like_count += 1;
      }

      this.$axios.put("/api/likes/posts/" + item.id);
    },
    cutUrl(item) {
      const url = item;
      const urlObj = new URL(url);;
      const params = new URLSearchParams(urlObj.search);
      this.jayuId = params.get('idxno')
    }

  },
  mounted() {
    this.kakaoHelper = new KakaoHelper(Kakao);
    this.getPolitic();
    this.initSwiper();

  }
}
