
import common from '../utils/common'
export default {
    mixins: [common],
    data() {
        return {
            active: false,
            activeFinder: false,
            activeSidebar: false,
            activeSearch: false,
            activeDisSearch: false,
            container: {},
            searchingDistrict: {},
            form: {
                district: "",
                word: "",
            },
        }
    },
    methods: {
        handleActive() {
            this.active = !this.active;
            this.activeSearch = false;
        },
        setContainer(container) {
            this.container = container;
        },
        scrollTop() {
            window.scrollTo(0, 0);
        },

        search() {
            window.location.href = "/posts?word=" + this.form.word;
        },
        changeDistrict(data) {
            if (data.district)
                this.searchingDistrict = data.district;
        },
        searchDis() {
            this.activeDisSearch = false;
            this.toDistrict(this.searchingDistrict);
        },
        goToNotice(board) {
            this.$router.push(`/posts?board=${board}`);
        },

        toDistrict(district) {
            // if(!this.$auth.user)
            //     return this.$router.push("/auth/login");
            console.log(district, 22333333)
            if (district) {
                this.$store.commit("changeDistrict", district);

            } else {
                this.$store.commit("changeDistrict", {
                    id: 0,
                    district: "자유마을"
                });
                this.$router.push("/");
            }


            let route = this.$route.name;

            if (route === 'infos' || route === 'staff' || route === 'contacts'
                || route === 'notices') {
                if (district)
                    location.href = `/${route}`;
            } else if (route === 'index') {
                location.href = "/"
            }


            this.activeSidebar = false;
        },
        sideMenuToDistrict(district) {
            this.$store.commit("changeDistrict", district);
            location.href = "/"

        },

        toMain() {
            this.$store.commit("changeDistrict", {
                id: 0,
                district: "자유마을"
            });

            location.href = "/";

            this.activeSidebar = false;
        },

    },

    computed: {
        district() {
            return this.$store.state.district;
        },
        activityArea() {
            return this.$store.state.activityArea;
        },
        myDistricts() {
            return this.$auth.user.my_districts?.length > 0 ? [
                ...this.$auth.user.my_districts,
                this.activityArea.activity_id == this.$auth.user.district.id ? null : {
                    ...this.activityArea,
                    id: this.activityArea.activity_id,
                    district: {
                        ...this.activityArea,
                        id: this.activityArea.activity_id,
                    }
                }
            ] : [
                this.activityArea.activity_id == this.$auth.user.district.id ? null : {
                    ...this.activityArea,
                    id: this.activityArea.activity_id,
                    district: {
                        ...this.activityArea,
                        id: this.activityArea.activity_id,
                    }
                }
            ]
        }
    },

    async mounted() {
        await this.getActivityAreaInfo();
    }
}
