
export default {
    props: {

    },
    data() {
        return {

        }
    },

    methods: {
        organBlock() {
            alert('자유마을 조직 개편작업으로 인해 일시적으로 사용이 불가합니다');
        },
        block() {
            alert('현재 보수중입니다.');
        }
    },

    mounted() {

    }
}
