
export default {
  name: 'SectionFire',
  props: {
    contentItem: {
      type: Object,
      default: () => ({})
    }
  }
}
