
import Swiper from 'swiper';
import common from '@/utils/common';
export default {
  name: 'SectionEvent',
  mixins: [common],
  props: {
    eventList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: '2',
        centeredSlides: false,
        spaceBetween: 250,
        loop: true,
        autoPlay: {
          delay: 1000,
          disableOnInteraction: false,
        },
        observer: true,
        observeParents: true,
      },
    }
  },
  methods: {
    initSwiper() {
      this.swiper = new Swiper('.swiper-container', {
        // 옵션 설정
        on: {
          slideChange: function () {
            // 이벤트 리스너 내에서 현재 활성화된 슬라이드의 인덱스를 가져옵니다.
            var activeIndex = this.activeIndex;

            // 모든 슬라이드에 active 클래스를 제거합니다.
            document.querySelectorAll('.swiper-slide').forEach(function (slide) {
              slide.classList.remove('active');
            });

            // 활성화된 슬라이드에 active 클래스를 추가합니다.
            var activeSlide = document.querySelector('.swiper-slide:nth-child(' + (activeIndex + 1) + ')');
            if (activeSlide) {
              activeSlide.classList.add('active');
            }
          },
        },
      });
    }
  },
  mounted() {
    this.initSwiper();
  }
}
