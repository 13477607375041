
import common from '@/utils/common'
import Swiper from 'swiper';
export default {
  name: 'SectionActivity',
  mixins: [common],
  // props: {
  //   // 실시간 채팅 예고 시간
  //   liveTime: {
  //     type: String,
  //     default: ''
  //   },
  // },
  data() {
    return {
      itemList: [],
     swiperOptions: {
        slidesPerView: '2.3',
        centeredSlides: false,
        // spaceBetween: 10,
        // loop: true,
      },
    }
  },
  computed: {

  },
  methods: {
    initSwiper() {
      this.swiper = new Swiper('.swiper-container', {
    
      });
    },
 
  },
  mounted() {
    this.initSwiper();
  },
  created() {
    this.$axios.get('/api/home-activity', {
    })
      .then(response => {
        // 최신순 정렬
        this.itemList = response.data.sort((a, b) => b.id - a.id);
      })
      .catch(e => {
        console.log(e)
      })

  }
}
