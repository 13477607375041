import { render, staticRenderFns } from "./SectionCard.vue?vue&type=template&id=3c2ea928&scoped=true&"
import script from "./SectionCard.vue?vue&type=script&lang=js&"
export * from "./SectionCard.vue?vue&type=script&lang=js&"
import style0 from "./SectionCard.vue?vue&type=style&index=0&id=3c2ea928&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c2ea928",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {JayuLogo: require('/var/www/html/jayuvillage/components/JayuLogo.vue').default,Modal: require('/var/www/html/jayuvillage/components/modal.vue').default})
