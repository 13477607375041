
export default {
    props: {
        createUrl: {
            default(){
                return "/posts/create";
            }
        },
        btnName: {
            type: String,
            defualt: ""
        }
    },
    data() {
        return {

        }
    },

    methods: {
        scrollTop() {
            window.scrollTo(0, 0);
        },

        createPost(){
            if(!this.$auth.user)
                return this.$router.push("/auth/login");

            if(this.$store.state && this.$store.state.district.id == 0 && !this.$auth.hasScope("admin"))
                return alert("게시할 마을을 선택하세요.");

            return this.$router.push(this.createUrl);
        }
    },

    mounted() {

    }
}
