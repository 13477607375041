
import common from '@/utils/common';
export default {
  name: 'SectionStory',
  mixins: [common],
  props: {
    storyList: {
      type: Array,
      required: true
    },
  },
  methods: {
  }
}
