
export default {
  name: 'SectionAd',
  props: {
    bannerList: {
      type: Array,
      required: true
    }
  },
  methods: {

  }
}
