
export default {
    name: 'sectionRanking',
    props: {
        districtRegisterCounts: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        district() {
            return this.$store.state.district;
        },
    },
    watch: {
        district() {
            this.$emit('changeRanking', this.rankingUrl, 10);
        },
        toggleList(value) {
            this.rankingUrl = value;
            this.switchRankGuide(value);
            this.$emit('changeRanking', this.rankingUrl, 10);
            // this.getRankings(10);
        },
    },
    data() {
        return {
            // districtRegisterCounts: [],
            toggleList: 'total-rankings',
            rankingUrl: 'total-rankings',
            guideText: '',
        }
    },
    methods: {
        changeDistrictInRanking(data) {
            this.$store.commit("changeDistrict", data);
        },
        rankingCount(ranking) {
            if (this.rankingUrl === 'rankings')
                return ranking.now_week_count;

            if (this.rankingUrl === 'monthly-rankings')
                return ranking.now_month_count;

            if (this.rankingUrl === 'total-rankings')
                return ranking.count;

            return "-";
        },
        getBadgeSrc(rankingCount) {
            if (rankingCount >= 1000 && rankingCount < 2000) return 'main_icon_badge_1k.png';
            if (rankingCount >= 2000 && rankingCount < 3000) return 'main_icon_badge_2k.png';
            if (rankingCount >= 3000) return 'main_icon_badge_3k.png';
            if (rankingCount < 1000) return false;
        },
        getScaleClass(rankingCount, rankingType = 'unranked') {
            if (rankingType === 'ranked') {
                if (rankingCount == 0) return 'bg-grey-30 ';
                if (rankingCount < 100) return 'bg-red-30 ';
                if (rankingCount >= 100) return 'bg-orange ';
            }
            if (rankingCount == 0) return 'bg-grey-30 more';
            if (rankingCount < 100) return 'bg-red-30 more up';
            if (rankingCount >= 100) return 'bg-orange more';
        },
        switchRankGuide(toggleList) {
            let guideText = '';
            switch (toggleList) {
                case 'daily-rankings':
                    guideText = '전일';
                    break;
                case 'rankings':
                    guideText = '전주 토요일 00:00 ~ 일요일 23:59';
                    break;
                case 'monthly-rankings':
                    guideText = '매월 1회 (전달 1일부터 말일)';
                    break;
                case 'total-rankings':
                    guideText = '총 누적';
                    break;
            }
            this.guideText = guideText;
        },
    },
}
