
import common from '@/utils/common'
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
export default {
  name: 'SectionOpenChat',
  mixins: [common],
  // props: {
  //   // 실시간 채팅 예고 시간
  //   liveTime: {
  //     type: String,
  //     default: ''
  //   },
  // },
  data() {
    return {
      groupId: 3527,
      chats: [],
      message: '',
    }
  },
  computed: {

  },
  methods: {
    connect() {
      if (!window.Pusher)
        window.Pusher = Pusher;
      if (!this.echo) {
        this.echo = new Echo({
          broadcaster: "pusher",
          key: "668bac10cc6db3a1338c",
          cluster: "ap3",
          authEndpoint: '/api/messages',
        });
      }

      this.echo.channel(`chats` + this.groupId)
        .listen("JoinMessage", (e) => {
          if (this.chats.length >= 6) {
            this.chats.shift();
          }
          console.log(e.group);
          this.chats.push(e.group);
        });
    },

    async send() {
      if (this.$auth.user && this.$auth.user.id) {
        if (this.message !== '') {
          const data = {
            username: this.$auth.user.name,
            message: this.message,
            user_id: this.$auth.user.id,
            name: this.$auth.user.name,
            image: null,
            room_id: this.groupId
          };
          await this.$axios.post("/api/messages", data);

          this.message = '';
        } else {
          alert('메시지를 입력해주세요.');
          return;
        }
      } else {
        this.$router.push("/auth/login");

      }

    },

    listClick(id, name, profile, online) {
      if (profile == null) {
        profile = require("@/assets/images/profile.svg")
      }
      this.$router.push({
        // name: 'chat-view',
        path: '/chat/view',
        query: {
          groupId: id,
          userName: name,
          userProfile: profile,
          online: online
        },
      })
    },
  },
  mounted() {
    this.connect();
  },
  created() {
    this.$axios.get('/api/messages', {
      params: {
        room_id: this.groupId
      }
    })
      .then(response => {

        this.chats = response.data.data.slice(-6);
      })
      .catch(e => {
        console.log(e)
      })

  }
}
