
export default {
  name: 'QrContainer',
  props: {
    qrInfo: {
      type: Object,
      default: () => { }
    },
    qrData: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      qrSrc: require('@/assets/images/main/qr-login.png'),
      activeDisSearch: false,
    }
  },
  watch: {
    '$auth.user': {
      handler(oldVal, newVal) {
        if (newVal) {
          console.log('oldVal', oldVal);

          console.log('newVal', newVal);
        }

      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    convertSerial() {
      if (!this.$auth.user?.serial_number) return '';
      return this.$auth.user?.serial_number
        .slice(0, -1)
        .padStart(8, '0')
        .padStart(9, 'N')
        .replace(/(\w{1})(\d{4})(\d{4})/g, '$1-$2-$3');
    }
  },
  methods: {
    test() {
      this.activeDisSearch = true;
    }
  }
}
