
/**
 * items:array<string> (default) 을 받아 드랍다운 형식으로 만드는 컴포넌트
 * @displayName Dropdown
 */
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * 드랍다운 활성화 플래그
     */
    activate: {
      type: Boolean,
      default: false,
    },
    menuTitle: {
      type: String,
      default: "",
    },
    /**
     * 드랍다운 내용물
     */
    items: {
      type: Array,
      default: []
    },
    itemPropName: {
      type: String,
      default: "district"
    },

    /**
     * 미리 지정된 값
     */
    value: {
      type: String,
      default: null
    },
    /**
     * 선택된 아이템
     */
    selected: {
      type: Boolean,
      default: false,
    },
    /**
     * 테마 컬러
     */
    themeColor: {
      type: String,
      default: "#fff"
    },
    themeFontColor: {
      type: String,
      default: "#000"
    },
    /**
     * 테마 테두리
     */
    themeBorder: {
      type: String,
      default: "#eee"
    },
    /**
     *  선택창만 테두리 없음
     */
    noBorder: {
      type: Boolean,
      default: false
    },
    scrollable: {
      type: Boolean,
      default: false
    },
    /**
     * 스크롤 사이즈(곱하기 47px)
     */
  },
  computed: {
    computedActivated() {
      return this.activate;
    },
    isScrollable() {
      return this.scrollable ? 'scrollable' : '';
    },
    /**
     * items 의 내용물 차이로 인해 추가적으로 만들어진 부분
     */
    computedItems() {
      const items = this.items.map(item => {
        if (typeof item == 'string')
          return { value: item }
        if (typeof item === 'object') {
          if (this.menuTitle === "시/군/구 선택") {
            return item;
          } else {
            return {
              value: item[`${this.itemPropName}`],
              id: item.id
            }

          }
        }
      })
      return items;
    }
  },
  watch: {
    value: {
      handler(data) {
        const payload = this.computedItems.find(item => item.value === data);
        if (payload) {
          this.select(payload)
        } else {
          this.selecteItem = "";
        }
      },
      immediate: true
    },
  },
  data() {
    return {
      /**
       * 선택된 아이템을 표시하기 위해 사용
       */
      selectedItem: ''
    }
  },
  methods: {
    /**
     * 드랍다운 선택 시 리스너 콜백
     */
    select(item) {
      this.selectedItem = item.value;
      if (item.id) {
        this.$emit('change', item.id);
      } else {
        this.$emit('change', item.value);
      }
      this.toggleDropdown();
    },
    /**
     * 드랍다운 토글
     */
    toggleDropdown() {
      if (this.disabled) return;
      this.$emit('toggle')
    }
  },
}
