
import common from '../utils/common'
export default {
  props: {
    modalColor: {
      type: String,
      default: ''
    },
    btnTransparent: {
      type: Boolean,
      default: false
    },
    noPaddingModal: {
      type: Boolean,
      default: false
    },
    paddingModal: {
      type: Boolean,
      default: true
    },
    cancelBtn: {
      type: Boolean,
      default: true
    },
    cancel: {
      type: String,
      default: ''
    },
  },
  mixins: [common],
  methods: {
    emitEvent(eventName, payload = undefined) {
      this.$emit(eventName, payload);
    },
  },

}
