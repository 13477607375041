
import common from '@/utils/common'
export default {
  name: 'SectionLive',
  mixins: [common],
  props: {
    // 실시간 채팅 예고 시간
    liveTime: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      timer: null,
    }
  },
  computed: {
    formattedLiveTime() {
      const date = new Date(this.liveTime);
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hours = date.getHours();
      let minutes = date.getMinutes();
      const dayofweek = date.getDay();
      const dayOfWeekStr = ['일', '월', '화', '수', '목', '금', '토'];
      const yoill = dayOfWeekStr[dayofweek];
      month = month < 10 ? `0${month}` : month;
      day = day < 10 ? `0${day}` : day;
      hours = hours < 10 ? `0${hours}` : hours;
      minutes = minutes < 10 ? `0${minutes}` : minutes;
      return `${month}.${day} (${yoill}) ${hours}:${minutes}`;
    },
  },
  methods: {
    updateTimer() {
      const currentTime = new Date();
      const liveTime = new Date(this.liveTime);
      const diff = liveTime - currentTime;
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      let hours = Math.floor(diff / (1000 * 60 * 60));
      let minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((diff % (1000 * 60)) / 1000);
      hours = hours < 10 ? `0${hours}` : hours;
      minutes = minutes < 10 ? `0${minutes}` : minutes;
      seconds = seconds < 10 ? `0${seconds}` : seconds;

      this.timer = `${hours}:${minutes}:${seconds}`;

      if (diff < 0) {
        this.timer = null;
      }
    }
  },
  mounted() {
    this.updateTimer();
    setInterval(this.updateTimer, 1000)
  },
}
