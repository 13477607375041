
import * as Cookies from "js-cookie";
import { mapActions } from 'vuex';
import Loading from '../components/Loading.vue'
import SectionFire from '@/components/main/SectionFire.vue';
import SectionCard from '@/components/main/SectionCard.vue';
import SectionWebtoon from '@/components/main/SectionWebtoon.vue';
import SectionStory from '@/components/main/SectionStory.vue';
import SectionLive from '@/components/main/SectionLive.vue';
import SectionAd from '@/components/main/SectionAd.vue';
import SectionEvent from '@/components/main/SectionEvent.vue';
import SectionOpenChat from '@/components/main/SectionOpenChat.vue';
import SectionActivity from '@/components/main/SectionActivity.vue';
import SectionQuiz from '@/components/main/SectionQuiz.vue';
import SectionWebtoons from '@/components/main/SectionWebtoons.vue';

import SectionRanking from '@/components/posts/SectionRanking.vue'
export default {
  auth: false,
  components: {
    Loading,
    SectionFire,
    SectionCard,
    SectionWebtoon,
    SectionStory,
    SectionLive,
    SectionAd,
    SectionEvent,
    SectionOpenChat,
    SectionActivity,
    SectionQuiz,
    SectionWebtoons,
    SectionRanking
  },
  data() {
    return {
      loading: true,
      qrData: null,
      activateNoticePop: false,
      noticePopupContents: [],
      qrInfo: {},
      webtoonList: [],
      storyList: [],
      promotionList: [],
      fireList: [],
      rankings: [],
      liveTime: '2024-05-08T17:45:00',
    }
  },
  watch: {
    allLoaded(val) {
      if (val == true) {
        this.loading = false;
      }

    }
  },
  computed: {
    getHomeBanner2() {
      return this.$store.getters.getHomeBanner2;
    },
    allLoaded() {
      //webtoonList, storyList, promotionList
      return this.webtoonList.length > 0 && this.storyList.length > 0 && this.promotionList.length > 0;
    }
  },
  methods: {
    ...mapActions(['FETCH_HOME_BANNER1', 'FETCH_HOME_BANNER2', 'FETCH_HOME_BANNER3']),

    async getQr() {
      const response = await this.$axios.get(`/api/qrcode/${this.$auth.user.id}`)
      if (response.data) {
        this.qrData = response.data;
      }
    },
    async getQrInfo() {
      const response = await this.$axios.get('/api/auth/qrinfo')
      this.qrInfo = { ...response.data };
    },
    async countClick(click_type, click_id) {
      // console.log(click_type, click_id, 333)
      const res = await this.$axios.post('/api/click-count', { click_type, click_id });
    },
    async getWebtoon() {
      const response = await this.$axios.get('/api/webtoon');
      if (response.data) {
        this.webtoonList = response.data.post.sort((a, b) => {
          return b.id - a.id;
        });
        this.webtoonThumb = response.data.post[0].thumbnail;
        this.webtoonId = response.data.post[0].id;
      }
    },
    async getStoryList() {
      const response = await this.$axios.get('/api/promotion');
      if (response.data) {
        this.storyList = response.data.story.map((item) => {
          return {
            ...item,
            video_thumbnail: item.video_thumbnail ? item.video_thumbnail.replace(/0\.jpg/g, 'mqdefault.jpg') : ''
          }
        })
      }
    },
    async getPromotionList() {
      const response = await this.$axios.get('/api/promotion');
      if (response.data) {
        this.promotionList = response.data.promotion.map((item) => {
          return {
            ...item,
            video_thumbnail: item.video_thumbnail ? item.video_thumbnail.replace(/0\.jpg/g, 'mqdefault.jpg') : ''
          }
        })
      }
    },
    async getBanners() {
      let homeBanners;
      if (!this.$store.state.homebanner1.length > 0) {
        homeBanners = await this.$axios.get('/api/banners/home');
      }
      if (homeBanners?.data) {
        this.homeBanners = {
          ...homeBanners.data,
        };
        this.FETCH_HOME_BANNER1(this.homeBanners.banners.filter(banner => {
          return banner.position === 'top';
        }));
        this.FETCH_HOME_BANNER2(this.homeBanners.banners.filter(banner => {
          return banner.position === 'middle';
        }));
        this.FETCH_HOME_BANNER3(this.homeBanners.banners.filter(banner => {
          return banner.position === 'bottom';
        }));
      }
    },
    async getFireList() {
      const response = await this.$axios.get('api/fires');
      this.fireList = response.data.data.filter(item => this.calculateRemainingTime(item.date_at) > 0)
        .sort((a, b) => this.calculateRemainingTime(a.date_at) - this.calculateRemainingTime(b.date_at));;
    },
    async getRankings(rankingUrl, count) {
      // 서버 과부화방지용 json 데이터 로드
      // const rankings = await this.$axios.$get('/data/ranking_static_data.json')
      // this.districtRegisterCounts = rankings.districtRegisterCounts;

      // 기존 db 데이터 조회
      const response = await this.$axios.get(`/api/${rankingUrl}/${count}`);
      this.rankings = [...response.data.districtRegisterCounts];
    },

    async getNoticeContents() {
      try {
        const { data } = await this.$axios.get('/api/banners/popups')
        if (data.banners.length) {
          this.noticePopupContents = data.banners.filter(notice => {
            return !Cookies.get(`popup`)
          });
          if (this.noticePopupContents.length === 0) {
            Cookies.set('allPopClosedToday', true, { expires: 1, secure: false });
            return;
          };
          this.currentId = this.noticePopupContents[0].id;
          this.activateNoticePop = true;
        }
      } catch (error) {

      }
    },
    calculateRemainingTime(date) {
      const currentDate = new Date();
      const targetDate = new Date(date);
      const diffInSeconds = Math.floor((targetDate - currentDate) / 1000);
      return diffInSeconds > 0 ? diffInSeconds : 0;
    },
    handleDeepLink() {
      this.exceDeepLink();
      this.checkInstallApp();
    },
    exceDeepLink() {
      let url = 'jayuvillage://audio/';
      location.href = url;
    },

    checkInstallApp() {
      let check;
      let timer;

      const clearTimers = () => {
        clearInterval(check);
        clearTimeout(timer);
      };

      const isHideWeb = () => {
        if (document.webkitHidden || document.hidden) {
          clearTimers();
        }
      };

      check = setInterval(isHideWeb, 200);

      timer = setTimeout(() => {
        clearTimers();
        this.redirectStore();
      }, 500);
    },

    redirectStore() {
      const ua = navigator.userAgent.toLowerCase();
      if (confirm('자유마을 앱을 설치하시겠습니까?')) {
        if (ua.indexOf('android') > -1) {
          window.location.href = 'https://play.google.com/store/apps/details?id=com.puritech.jayuvillage&hl=ko';
        } else if (ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1) {
          window.location.href = 'https://apps.apple.com/kr/app/%EC%9E%90%EC%9C%A0%EB%A7%88%EC%9D%84/id6503334096';
        }
      }
    }
  },
  async mounted() {
    // if(this.$auth.user) {
    //   this.qrSrc = require('@/assets/images/main/qr-mark.png');
    // }
    if (!Cookies.get('allPopClosedToday')) {
      await this.getNoticeContents();
    }
    if (this.$auth.user) {
      await this.getQr();
      await this.getQrInfo();
    }
    await this.getRankings('total-rankings', 10);
    await this.getFireList();
    await this.getBanners();
    await this.getWebtoon();
    await this.getStoryList();
    await this.getPromotionList();

  },
}
