// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/ribbon.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".m-title.type01[data-v-7b23377e]{margin-bottom:10px;text-align:center}.swiper-slide .story-container[data-v-7b23377e]{background-color:linear-gradient(180deg,hsla(0,0%,100%,0),#fff);height:300px;overflow:hidden;position:relative}.swiper-slide .story-container .ribbon[data-v-7b23377e]{align-items:center;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%;background-size:cover;display:flex;flex-direction:column;justify-content:flex-start;min-height:80px;min-width:12%;padding-top:10px;position:absolute;right:3%;top:-5px}.swiper-slide .story-container .ribbon .year[data-v-7b23377e]{font-size:10px}.swiper-slide .story-container .ribbon .month[data-v-7b23377e]{font-size:14px;font-weight:500}.swiper-slide .story-container .img[data-v-7b23377e]{border-radius:10px;height:65%;-o-object-fit:cover;object-fit:cover;width:100%}.swiper-slide .story-container .content-container[data-v-7b23377e]{align-items:center;background-color:#fff;border:1px solid #bdbdbd;border-radius:10px;bottom:20%;display:flex;flex-flow:column;height:80px;justify-content:center;left:5%;position:absolute;width:90%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
