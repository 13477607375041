
import common from '@/utils/common'
import Swiper from 'swiper';
export default {
  name: 'SectionActivity',
  mixins: [common],
  // props: {
  //   // 실시간 채팅 예고 시간
  //   liveTime: {
  //     type: String,
  //     default: ''
  //   },
  // },
  data() {
    return {
      average: null
    }
  },
  computed: {
    progressWidth() {
      return this.average;
    }
  },
  methods: {
    testClick() {
      if (!this.$auth.user) {
        return this.$router.push("/auth/login");
      } else {
        this.$router.push('/quiz')
      }
    }

  },
  mounted() {

  },
  created() {
    this.$axios.get('/api/my-score', {
    })
      .then(response => {
        this.average = response.data.mid_point.toFixed(0);
      })
      .catch(e => {
        console.log(e)
      })

  }
}
