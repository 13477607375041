
import * as Cookies from "js-cookie";
export default {
  name: 'MainPopup',
  props: {
    activateNoticePop: {
      type: Boolean,
      default: false,
    },
    noticePopupContents: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      modalSlideActive: true,
      modalSlideCurrentPage: 0,
    }
  },
  methods: {
    handleLink(slideItem) {

      if (!this.$store.state.isFlutter) {
        const ua = navigator.userAgent.toLowerCase();
        console.log(ua, 'ua')
        if (ua.indexOf('android') > -1) {
          window.location.href = slideItem.link_url_android;
        } else if (ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1) {
          window.location.href = slideItem.link_url_ios;
        }
      } else {
        window.location.href = slideItem.link_url;
      }
    },
    closeAllDay() {
      Cookies.set(`popup`, "closed", { expires: 1, secure: false });
      this.closeNoticePop();
      const lastIndex = this.noticePopupContents.length - 1;

    },
    justClose() {
      this.closeNoticePop();
    },
    closeNoticePop() {
      this.$emit('closeNoticePop');
    },
    // 공지 모달 슬라이드
    showNext() {
      console.log(this.$refs.noticeCarousel, 'forward')
      this.$refs.noticeCarousel.handleNavigation('forward');
      this.getNoticeCurrentPage();
    },
    showPrev() {
      console.log(this.$refs.noticeCarousel, 'backward')
      this.$refs.noticeCarousel.handleNavigation('backward');
      this.getNoticeCurrentPage();
    },
    pauseAutoplay() {
      this.$refs.noticeCarousel.pauseAutoplay();
      this.modalSlideActive = false;
    },
    startAutoplay() {
      this.$refs.noticeCarousel.restartAutoplay();
      this.modalSlideActive = true;
    },
    getNoticeCurrentPage() {
      this.modalSlideCurrentPage = this.$refs.noticeCarousel.currentPage + 1;
    },
    listenAutoplay() {
      this.getNoticeCurrentPage();
    },
  },
  async mounted() {

  }
}
